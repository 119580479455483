import React, { Suspense, useEffect, useState } from 'react';
import $ from "jquery";
import AppStateProvider from './hook/AppStateProvider'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const Profile = React.lazy(() => import('./pages/header'));
const Introduction = React.lazy(() => import('./pages/introduction'));
const Main = React.lazy(() => import('./pages/main'));
const About = React.lazy(() => import('./pages/about'));
const Contact = React.lazy(() => import('./pages/contact'));
const Projects = React.lazy(() => import('./pages/projects'));

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));



function App () {
  const classes = useStyles();
  var backgroundGradient = [
    { color: "rgb(255, 75, 44)", gradient: "linear-gradient(to top, rgb(255, 75, 44), rgb(255, 90, 170))" },
    // { color: "rgb(191 55 100)", gradient: "linear-gradient(to top, rgb(195, 55, 100), rgb(29, 38, 113))" },
    { color: "rgb(0, 121, 145)", gradient: "linear-gradient(to top, rgb(0, 121, 145), rgb(147, 255, 222))" },
    { color: "rgb(255, 119, 54)", gradient: "linear-gradient(to top, rgb(255, 119, 54), rgb(255, 214, 98))" },
    // { color: "rgb(137, 37, 62)", gradient: "linear-gradient(to top, rgb(137, 37, 62), rgb(58, 97, 134))" },
    // { color: "rgb(20, 31, 48)", gradient: "linear-gradient(to top, rgb(20, 30, 48), rgb(36, 59, 85))" },
  ]

  // eslint-disable-next-line
  const [background, setBackground] = useState(backgroundGradient[Math.floor((Math.random() * backgroundGradient.length))])
  var backgroundGradientStyle = backgroundGradient[Math.floor((Math.random() * backgroundGradient.length))]
  useEffect(() => {
    $(window).scroll(function (e) {
      var scroll = $(window).scrollTop();
      var scrollValue = 70
      if ('ontouchstart' in document.documentElement) {
        scrollValue = 20;
      }

      if (scroll > scrollValue) {
        $("nav ul li a").css({ "color": "black" });
        $("nav").css({ "background": "#fff", "color": "#000", "box-shadow": `${backgroundGradientStyle.color} 0 0 2px 2px` });
      } else {
        $("nav ul li a").css({ "color": "white" });
        $("nav").css({ "background": "transparent", "box-shadow": "none" });
      }
    });
  })

  const changeGradient = () => {
    setBackground(backgroundGradient[Math.floor((Math.random() * backgroundGradient.length))])
  }

  const containers = {
    background: backgroundGradientStyle,
    changeGradient
  };

  return (
    <AppStateProvider containers={containers}>
      <Suspense fallback={<Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>}>
        <Profile />
        <Main>
          <Introduction />
          <About />
          <Projects />
        </Main>
        <Contact />
      </Suspense>
    </AppStateProvider>
  );
}

export default App;
