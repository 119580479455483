import React from "react";
import PropTypes from "prop-types";
import AppContext from "./AppContext";


const AppStateProvider = ({ children, containers = {} }) => {
    return (
        <AppContext.Provider value={[containers]}>
            {children}
        </AppContext.Provider>
    );
};

AppStateProvider.propTypes = {
    children: PropTypes.node.isRequired,
    containers: PropTypes.object.isRequired,
};

export default AppStateProvider;
